<template>
  <div class="dimension-sort-dialog">
    <el-dialog
      title="分类维度排序"
      :visible.sync="showDialog"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span>
        <div class="sort-box">
          <div class="left">
            <el-radio-group :value="dimensionIndex" @input="onChangeRadio">
              <el-radio
                v-for="(item, index) in sortList"
                :key="index"
                :label="index"
                border
                >{{ item.DimeName }}</el-radio
              >
            </el-radio-group>
          </div>
          <div class="right">
            <el-button
              type="primary"
              icon="el-icon-caret-top"
              size="mini"
              :disabled="
                sortDiabled || dimensionIndex < 1 || sortList.length < 1
              "
              plain
              @click="onChangeSort(-1)"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-caret-bottom"
              size="mini"
              :disabled="
                sortDiabled ||
                  dimensionIndex == -1 ||
                  dimensionIndex == sortList.length - 1 ||
                  sortList.length < 1
              "
              plain
              @click="onChangeSort(2)"
            ></el-button>
          </div>
        </div>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取消</el-button>
        <el-button type="primary" @click="sortAllDimension">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Member from '@/api/member.js'

export default {
  data() {
    return {
      dimensionIndex: -1,
      sortList: [], // 维度排序列表
      showDialog: false, // 对话框显示
      sortDiabled: false, // 排序防抖
    };
  },

  methods: {
    // 保存分类维度排序
    async sortAllDimension(){
      let submitData = []
      await this.sortList.forEach(item => {
        submitData.push(item.DimeGuid)
      });

      try {
        let { errcode } = await Member.sortAllDimension({
          DimeGuids: submitData
        })
        if(errcode == 0){
          this.$message.success('保存成功')
          this.$emit('success');
          this.onCloseDialog()
        }
      } catch (err) {
        console.log(sortAllDimension);
      }
    },

    // 单选框改变事件
    onChangeRadio(event) {
      this.dimensionIndex = event;
    },

    // 上下移动事件
    onChangeSort(value) {
      this.sortDiabled = true;
      let { dimensionIndex, sortList } = this;

      sortList.splice(dimensionIndex + value, 0, sortList[dimensionIndex]);
      sortList.splice(dimensionIndex + (value > 0 ? 0 : 1), 1);
      this.dimensionIndex = dimensionIndex + (value > 0 ? 1 : -1);
      this.sortDiabled = false;
    },

    // 打开对话框
    onShowDialog(data) {
      this.sortList = JSON.parse(JSON.stringify(data));
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.dimensionIndex = -1;
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.dimension-sort-dialog {
  .sort-box {
    .flex-row;
    max-height: 600px;
    overflow-y: auto;

    .left {
      width: 60%;
      .el-radio-group {
        .flex-col;
        align-items: unset;

        .el-radio {
          width: 100%;
          margin: 5px 0;
        }
      }
    }

    .right {
      .flex-col;
      width: 40%;
      height: 100%;

      .el-button {
        margin: 10px 0;
        ::v-deep i {
          font-size: 24px;
        }
      }
    }
  }
}
</style>
