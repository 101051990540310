<template>
  <div class="edit-dialog">
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      top="2%"
      width="64%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="content-box">
        <el-form :model="dimension" ref="form" label-width="100px" size="mini">
          <el-form-item label="分类维度">
            <el-input
              v-model="dimension.DimeName"
              aria-placeholder="请输入1-6个字"
              :minlength="1"
              :maxlength="6"
            ></el-input>
          </el-form-item>

          <el-form-item label="是否自动分类">
            <el-switch
              v-model="dimension.AutoEnable"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
            <span v-show="dimension.AutoEnable" class="tips"
              >设定规则后，顾客按规则自动匹配对应的分类</span
            >
          </el-form-item>

          <el-form-item v-if="dimension.AutoEnable" label="分类条件">
            <div class="condition-box">
              <div class="condition-item">
                <div>
                  <el-checkbox
                    v-model="dimension.AutoLastExpeTimeEnable"
                    :checked="dimension.AutoLastExpeTimeEnable ? true : false"
                    :true-label="1"
                    :false-label="0"
                    >最后消费时间</el-checkbox
                  >
                </div>
                <span class="tips">顾客最后一次到店消费项目/卖品的时间</span>
              </div>

              <div class="condition-item">
                <div>
                  <el-checkbox
                    v-model="dimension.AutoExpenseAmountEnable"
                    :checked="dimension.AutoExpenseAmountEnable ? true : false"
                    :true-label="1"
                    :false-label="0"
                    >消费金额</el-checkbox
                  >
                  <el-select
                    v-model="dimension.AutoExpenseAmountRange"
                    :disabled="!dimension.AutoExpenseAmountEnable"
                  >
                    <el-option
                      v-for="item in timeList"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <span class="tips"
                  >顾客{{
                    dimension.AutoExpenseAmountRange
                  }}个月内消耗项目和卖品的总额</span
                >
              </div>

              <div class="condition-item">
                <div>
                  <el-checkbox
                    v-model="dimension.AutoExpenseCountEnable"
                    :checked="dimension.AutoExpenseCountEnable ? true : false"
                    :true-label="1"
                    :false-label="0"
                    >消费次数</el-checkbox
                  >
                  <el-select
                    v-model="dimension.AutoExpenseCountRange"
                    :disabled="!dimension.AutoExpenseCountEnable"
                  >
                    <el-option
                      v-for="item in timeList"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <span class="tips"
                  >顾客{{
                    dimension.AutoExpenseCountRange
                  }}个月内到店消费项目和卖品的次数（一个服务单算一次）</span
                >
              </div>

              <div class="condition-item">
                <div>
                  <el-checkbox
                    v-model="dimension.AutoAvgPriceEnable"
                    :checked="dimension.AutoAvgPriceEnable ? true : false"
                    :true-label="1"
                    :false-label="0"
                    >客单价</el-checkbox
                  >
                  <el-select
                    v-model="dimension.AutoAvgPriceRange"
                    :disabled="!dimension.AutoAvgPriceEnable"
                  >
                    <el-option
                      v-for="item in timeList"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <span class="tips">顾客的客单价=消费金额/消费次数</span>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="分类规则">
            <div
              v-if="dimension.AutoEnable"
              style="color: red;font-size: 14px;"
            >
              请按顺序由高到低设置分类规则，以便正确匹配会员的分类
              <el-popover placement="bottom" trigger="hover">
                <div class="content">
                  <div class="title">示例图</div>
                  <el-image :src="imgUrl"></el-image>
                </div>
                <i class="el-icon-warning-outline" slot="reference"></i>
              </el-popover>
            </div>

            <div class="rule-table">
              <el-table
                :data="kindList"
                max-height="400px"
                size="mini"
                border
                stripe
              >
                <el-table-column label="分类名称" fixed="left" min-width="130">
                  <template slot-scope="scope">
                    <el-input
                      class="kind-input"
                      v-model="scope.row.KindName"
                      placeholder="请输入1-6个字"
                      :minlength="1"
                      :maxlength="6"
                      size="mini"
                      clearable
                      @input="onChangeKindName($event, scope.$index)"
                    ></el-input>
                  </template>
                </el-table-column>

                <el-table-column fixed="left" min-width="100">
                  <template slot="header" slot-scope="scope">
                    分类标识
                    <el-popover placement="top" trigger="hover">
                      <div class="content">
                        <div>分类标识将作为小标签的样式</div>
                        <div>显示在顾客列表、顾客档案中</div>
                      </div>
                      <i class="el-icon-warning-outline" slot="reference"></i>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <div class="logo-template">
                      <el-input
                        class="logo-input"
                        :style="'background:' + scope.row.SignColor"
                        :maxlength="1"
                        v-model="scope.row.SignText"
                        size="mini"
                      ></el-input>
                      <el-color-picker
                        v-model="scope.row.SignColor"
                        :predefine="colorList"
                      ></el-color-picker>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  v-if="!dimension.AutoEnable"
                  label="分类规则"
                  width="440"
                >
                  <template slot-scope="scope">
                    <el-input
                      style="max-width: unset;width: 100%;"
                      v-model="scope.row.RuleDesc"
                      :minlength="1"
                      :maxlength="100"
                      type="textarea"
                      placeholder="请输入1~100的分类规则"
                      :autosize="{ minRows: 1, maxRows: 2 }"
                      @change=""
                    ></el-input>
                  </template>
                </el-table-column>

                <el-table-column
                  v-if="dimension.AutoLastExpeTimeEnable"
                  width="240"
                >
                  <template slot="header" slot-scope="scope">
                    最后消费时间
                    <el-popover placement="top" trigger="hover">
                      <div class="content">
                        <div>顾客最后一次到店消费项目/</div>
                        <div>卖品的时间</div>
                      </div>
                      <i class="el-icon-warning-outline" slot="reference"></i>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <div class="last-template">
                      <el-select v-model="scope.row.AutoLastExpeTimeSymbol">
                        <el-option
                          v-for="item in symbols"
                          :key="item.Value"
                          :label="item.Name"
                          :value="item.Value"
                        >
                        </el-option>
                      </el-select>
                      <el-input
                        class="kind-input"
                        v-model="scope.row.AutoLastExpeTimeValue"
                        placeholder="输入整数"
                        size="mini"
                        clearable
                        @change="
                          onChangeInput(
                            $event,
                            scope.$index,
                            'AutoLastExpeTimeValue'
                          )
                        "
                      ></el-input>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  v-if="dimension.AutoExpenseAmountEnable"
                  width="240"
                >
                  <template slot="header" slot-scope="scope">
                    消费金额（{{ dimension.AutoExpenseAmountRange }}个月内）
                    <el-popover placement="top" trigger="hover">
                      <div class="content">
                        <div>顾客3个月内消耗项目和卖品</div>
                        <div>的总额</div>
                      </div>
                      <i class="el-icon-warning-outline" slot="reference"></i>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <div class="amount-template">
                      <el-select v-model="scope.row.AutoExpenseAmountSymbol">
                        <el-option
                          v-for="item in symbols"
                          :key="item.Value"
                          :label="item.Name"
                          :value="item.Value"
                        >
                        </el-option>
                      </el-select>
                      <el-input
                        class="kind-input"
                        v-model="scope.row.AutoExpenseAmountValue"
                        placeholder="输入整数"
                        size="mini"
                        clearable
                        @change="
                          onChangeInput(
                            $event,
                            scope.$index,
                            'AutoExpenseAmountValue'
                          )
                        "
                      ></el-input>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  v-if="dimension.AutoExpenseCountEnable"
                  width="240"
                >
                  <template slot="header" slot-scope="scope">
                    消费次数（{{ dimension.AutoExpenseCountRange }}个月内）
                    <el-popover placement="top" trigger="hover">
                      <div class="content">
                        <div>顾客12个月内到店消费项目</div>
                        <div>和卖品的次数（一个服务单算</div>
                        <div>一次）</div>
                      </div>
                      <i class="el-icon-warning-outline" slot="reference"></i>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <div class="count-template">
                      <el-select v-model="scope.row.AutoExpenseCountSymbol">
                        <el-option
                          v-for="item in symbols"
                          :key="item.Value"
                          :label="item.Name"
                          :value="item.Value"
                        >
                        </el-option>
                      </el-select>
                      <el-input
                        class="kind-input"
                        v-model="scope.row.AutoExpenseCountValue"
                        placeholder="输入整数"
                        size="mini"
                        clearable
                        @change="
                          onChangeInput(
                            $event,
                            scope.$index,
                            'AutoExpenseCountValue'
                          )
                        "
                      ></el-input>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  v-if="dimension.AutoAvgPriceEnable"
                  width="240"
                >
                  <template slot="header" slot-scope="scope">
                    客单价（{{ dimension.AutoAvgPriceRange }}个月内）
                    <el-popover placement="top" trigger="hover">
                      <div class="content">
                        <div>顾客的客单价=消费金额/消</div>
                        <div>费次数</div>
                      </div>
                      <i class="el-icon-warning-outline" slot="reference"></i>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <div class="price-template">
                      <el-select v-model="scope.row.AutoAvgPriceSymbol">
                        <el-option
                          v-for="item in symbols"
                          :key="item.Value"
                          :label="item.Name"
                          :value="item.Value"
                        >
                        </el-option>
                      </el-select>
                      <el-input
                        class="kind-input"
                        v-model="scope.row.AutoAvgPriceValue"
                        placeholder="输入整数"
                        size="mini"
                        clearable
                        @change="
                          onChangeInput(
                            $event,
                            scope.$index,
                            'AutoAvgPriceValue'
                          )
                        "
                      ></el-input>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="分类编号" min-width="100">
                  <template slot-scope="scope">
                    <div v-if="scope.row.KindNo">{{ scope.row.KindNo }}</div>
                    <div v-else class="no-template">
                      <span>- -</span>
                      <span class="tips">自动生成</span>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" min-width="280">
                  <template slot-scope="scope">
                    <el-popover placement="left" trigger="hover">
                      <div class="control-popover">
                        <el-button
                          type="primary"
                          size="mini"
                          @click="onAddKindRule(scope.$index, 0)"
                          >在上方新增</el-button
                        >
                        <el-button
                          type="primary"
                          size="mini"
                          @click="onAddKindRule(scope.$index, 1)"
                          >在下方新增</el-button
                        >
                      </div>
                      <el-button type="primary" size="mini" slot="reference"
                        >新增一行</el-button
                      >
                    </el-popover>

                    <el-button
                      type="danger"
                      size="mini"
                      @click="onDelKindRule(scope.$index)"
                      >删除</el-button
                    >

                    <el-button
                      v-if="scope.row.KindGuid"
                      type="danger"
                      size="mini"
                      @click="clearDimensionKindMaps(scope.$index)"
                      >清空分类</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-button
                style="width: 100%;text-align: left;"
                type="primary"
                size="mini"
                plain
                @click="onAddKindRule(kindList.length - 1, 1)"
                >+ 新增</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="saveDimensionEditor">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import member from "@/api/member.js";
import exampleImg from "@/assets/img/example.png";

// let imgUrl = exampleImg
export default {
  data() {
    return {
      imgUrl: exampleImg,
      title: "",
      colorBan: 0,
      colorList: [
        "#003666",
        "#00AEFF",
        "#8E42E7",
        "#B84692",
        "#FF4F81",
        "#FF6C5F",
        "#FEC168",
        "#2CDE98",
        "#1CC7D0",
      ],
      timeList: [
        { text: "1个月", value: 1 },
        { text: "3个月", value: 3 },
        { text: "6个月", value: 6 },
        { text: "12个月", value: 12 },
      ],
      symbols: [], // 条件数据
      kindList: [], // 分类列表
      dimension: {}, // 维度信息
      submitData: {},
      showDialog: false,
    };
  },

  methods: {
    // 初始化维度分类信息
    async initDimensionEditor() {
      try {
        let { data } = await member.initDimensionEditor({
          dime_guid: this.submitData.DimeGuid || "",
        });

        let dimension = {
          ...data.editor.Dimension,
          AutoAvgPriceRange: data.editor.Dimension.AutoAvgPriceRange || 12,
          AutoExpenseAmountRange:
            data.editor.Dimension.AutoExpenseAmountRange || 12,
          AutoExpenseCountRange:
            data.editor.Dimension.AutoExpenseCountRange || 12,
        };
        let kindList = data.editor.KindList;
        if (!this.submitData.DimeGuid) {
          kindList[0].SignColor = this.colorList[this.colorBan];
          this.colorBan++;
        }
        this.dimension = dimension;
        this.kindList = kindList;
        this.symbols = data.symbols;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存维度分类
    async saveDimensionEditor() {
      let { dimension, kindList } = this;

      if (!dimension.DimeName) {
        return this.$message.error("请输入分类维度的名称");
      } else if (
        dimension.AutoEnable &&
        !dimension.AutoLastExpeTimeEnable &&
        !dimension.AutoExpenseAmountEnable &&
        !dimension.AutoExpenseCountEnable &&
        !dimension.AutoAvgPriceEnable
      ) {
        return this.$message.error("必须选择一项分类条件");
      } else {
        for (let i = 0; i < kindList.length; i++) {
          if (!kindList[i].KindName) {
            return this.$message.error("请输入分类名称");
          } else if (!dimension.AutoEnable && !kindList[i].RuleDesc) {
            return this.$message.error("请输入分类规则");
          } else if (!kindList[i].SignText) {
            return this.$message.error("请输入分类标识");
          } else if (
            dimension.AutoEnable &&
            dimension.AutoLastExpeTimeEnable &&
            (!kindList[i].AutoLastExpeTimeSymbol ||
              kindList[i].AutoLastExpeTimeValue == "")
          ) {
            return this.$message.error("请按规则填写最后消费时间");
          } else if (
            dimension.AutoEnable &&
            dimension.AutoExpenseAmountEnable &&
            (!kindList[i].AutoExpenseAmountSymbol ||
              kindList[i].AutoExpenseAmountValue == "")
          ) {
            return this.$message.error("请按规则填写消费金额");
          } else if (
            dimension.AutoEnable &&
            dimension.AutoExpenseCountEnable &&
            (!kindList[i].AutoExpenseCountSymbol ||
              kindList[i].AutoExpenseCountValue == "")
          ) {
            return this.$message.error("请按规则填写消费次数");
          } else if (
            dimension.AutoEnable &&
            dimension.AutoAvgPriceEnable &&
            (!kindList[i].AutoAvgPriceSymbol ||
              kindList[i].AutoAvgPriceValue == "")
          ) {
            return this.$message.error("请按规则填写客单价");
          }
        }
      }

      await kindList.forEach((item, index) => (item.Sort = index + 1));

      await this.$nextTick(async () => {
        try {
          let { errcode } = await member.saveDimensionEditor({
            Dimension: dimension,
            KindList: kindList,
          });
          if (errcode == 0) {
            this.$message.success("保存成功");
            this.$emit("success");
            this.onCloseDialog();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 新增一行分类规则
    async onAddKindRule(kindIndex = 0, value = 0) {
      let { kindList, colorList } = this;
      let param = await {
        AutoAvgPriceSymbol: "",
        AutoAvgPriceValue: 0,
        AutoExpenseAmountSymbol: "",
        AutoExpenseAmountValue: 0,
        AutoExpenseCountSymbol: "",
        AutoExpenseCountValue: 0,
        AutoLastExpeTimeSymbol: "",
        AutoLastExpeTimeValue: 0,
        KindGuid: "",
        KindName: "",
        KindNo: "",
        RuleDesc: "",
        SignColor: colorList[this.colorBan],
        SignText: "",
        Sort: 0,
      };

      await kindList.splice(kindIndex + value, 0, param);

      await this.colorBan++;

      await this.$nextTick(() => {
        if (this.colorBan == colorList.length - 1) {
          this.colorBan = 0;
        }
      });
    },

    // 清空会员关联的分类
    clearDimensionKindMaps(index) {
      let { kindList, dimension } = this;
      this.$confirm(
        `清空【${kindList[index].KindName}】会员分类将无法恢复，确定清空吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(async () => {
        try {
          let { errcode } = await member.clearDimensionKindMaps({
            dime_guid: dimension.DimeGuid,
            kind_guid: kindList[index].KindGuid,
          });
          if (errcode == 0) {
            this.$message.success(`【${kindList[index].KindName}】分类已清空`);
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 删除分类行
    onDelKindRule(kindIndex) {
      this.kindList.splice(kindIndex, 1);
    },

    // 分类名称输入框改变事件
    onChangeKindName(event, kindIndex) {
      this.kindList[kindIndex].SignText = event.slice(0, 1);
    },

    // 输入框改变事件
    onChangeInput(event, index, text) {
      // console.log(event);
      event === ""
        ? ""
        : (this.kindList[index][text] = Math.floor(Number(event)));
    },

    // 打开对话框
    onShowDialog(event = {}) {
      this.title = `${event.DimensionGuid ? "修改" : "新增"}分类维度`;
      this.submitData = JSON.parse(JSON.stringify(event));
      this.$nextTick(() => {
        this.initDimensionEditor();
        this.showDialog = true;
      });
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
      this.dimension = {};
      this.kindList = [];
      this.colorBan = 0
    },
  },
};
</script>

<style lang="less" scoped>
.edit-dialog {
  .content-box {
    height: 720px;
    .el-input {
      width: 200px;
    }

    .tips {
      margin-left: 10px;
      color: #aaa;
    }
    .condition-box {
      .flex-col;
      align-items: unset;

      .condition-item {
        .flex-row;
        margin-bottom: 10px;

        > div {
          width: 220px;
          .el-checkbox {
            width: 120px;
          }

          .el-select,
          .el-input {
            width: 100px;
          }
        }
      }
    }

    .rule-table {
      .kind-input {
        width: auto;
      }

      .logo-template,
      .last-template,
      .amount-template,
      .count-template,
      .price-template,
      .no-template {
        .flex-row;
        .el-input {
          margin: 0 10px;
        }
      }

      .logo-template {
        ::v-deep input,
        .el-input {
          padding: 0;
          width: 24px;
          height: 24px;
          text-align: center;
          color: white;
          caret-color: white;
          border: unset !important;
          border-radius: 50%;
          background-color: unset;
        }
      }

      .no-template {
      }

      .el-popover__reference-wrapper .el-button {
        margin-right: 10px;
      }
    }
  }
}
.market-i();
.market-popover();
.el-popover {
  .control-popover {
    .flex-col;
    padding: 4px 8px !important;
    box-sizing: border-box;

    ::v-deep .el-button {
      margin: 5px 0;
    }
  }
}
</style>
