<template>
  <div class="dimension">
    <div class="df-row">
      <div class="df-col">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="onShowEdit"
          >新增维度</el-button
        >
      </div>
      <div class="df-col">
        <el-button
          icon="dfWebIcon dfWebIcon-31liebiao"
          size="mini"
          @click="onShowDimenSort"
          >分类维度排序</el-button
        >
      </div>
    </div>

    <div class="df-row dimension-box">
      <div
        class="dimension-item"
        v-for="(dimenItem, dimenIndex) in dimensionList"
        :key="dimenIndex"
      >
        <div class="dimension-name">
          <span>{{ dimenItem.DimeName }}</span>
          <i class="el-icon-edit-outline" @click="onShowEdit(dimenItem)"></i>
          <i
            v-if="dimenItem.DimeGuid"
            class="el-icon-delete"
            @click="deleteDimension(dimenItem)"
          ></i>
          <el-button
            type="danger"
            size="mini"
            @click="clearDimensionKindMaps(dimenItem)"
            >清空会员分类</el-button
          >
          <el-button
            v-if="dimenItem.AutoEnable"
            type="warning"
            size="mini"
            @click="calcuDimensionKindMaps(dimenItem)"
            >重设会员分类</el-button
          >
        </div>

        <div class="kind-table">
          <el-table :data="dimenItem.KindList" size="mini" border stripe>
            <el-table-column
              prop="KindName"
              label="分类名称"
              width="160"
            ></el-table-column>
            <el-table-column prop="KindName" label="分类标识" width="70">
              <template slot-scope="scope">
                <div
                  class="kind-logo"
                  :style="'background-color:' + scope.row.SignColor"
                >
                  {{ scope.row.SignText }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="分类规则">
              <template slot-scope="scope">
                <el-tag
                  v-for="(ruleItem, ruleIndex) in scope.row.RuleDesc"
                  :key="ruleIndex"
                  size="medium"
                  effect="plain"
                  >{{ ruleItem }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="KindNo"
              label="分类编号"
              width="160"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <DimensionSortDialog
      ref="dimensionSortDialog"
      @success="getDimensionSelector"
    ></DimensionSortDialog>

    <EditDialog ref="editDialog" @success="getDimensionSelector"> </EditDialog>
  </div>
</template>

<script>
import member from "@/api/member.js";

import DimensionSortDialog from "./components/dimension-sort-dialog.vue";
import EditDialog from "./components/edit-dialog.vue";
export default {
  components: { EditDialog, DimensionSortDialog },

  data() {
    return {
      dimensionList: [],
    };
  },

  created() {
    this.getDimensionSelector();
  },

  mounted() {},

  destroyed() {},

  methods: {
    // 获取维度分类全部数据
    async getDimensionSelector() {
      try {
        let { data } = await member.getDimensionSelector();
        this.dimensionList = data.DimensionList;
      } catch (e) {
        console.error(e);
      }
    },

    // 删除维度
    async deleteDimension(item) {
      // this.$confirm(
      //   "删除的同时也会删除该维度的所有会员分类，确定删除该维度吗？",
      //   "提示",
      //   {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   }
      // ).then(async () => {
      let submitData = {
        dime_guid: item.DimeGuid,
      };
      // let delKindErrcode = 0;
      // if (item.KindList.length > 0) {
      //   let res = await member.clearDimensionKinds(submitData);
      //   delKindErrcode = await res.errcode;
      // }

      // await this.$nextTick(async () => {
      // if (delKindErrcode == 0) {
      let { errcode } = await member.deleteDimension(submitData);
      if (errcode == 0) {
        this.$message.success("删除成功");
        this.getDimensionSelector();
      }
      // }
      // });
      // });
    },

    // 清空所有会员关联的分类
    clearDimensionKindMaps(item) {
      this.$confirm("清空所有的会员分类将无法恢复，确定清空吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await member.clearDimensionKindMaps({
            dime_guid: item.DimeGuid,
          });
          if (errcode == 0) {
            this.$message.success("已全部清空");
            this.getDimensionSelector();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 重设会员分类
    calcuDimensionKindMaps(item) {
      this.$confirm("确定要重设已清空的会员分类吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await member.calcuDimensionKindMaps({
            dime_guid: item.DimeGuid,
          });
          if (errcode == 0) {
            this.$message.success("重设成功");
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 打开新增、修改分类维度对话框
    onShowEdit(event) {
      this.$refs.editDialog.onShowDialog(event);
    },

    // async onDeleteMemberClass(event) {
    //   this.$confirm(`是否删除该‘${event.KindName}’`, "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(async () => {
    //       try {
    //         let { data, errcode, errmsg } = await member.deleteMemberkind({
    //           kind_guid: event.KindGuid,
    //         });
    //         if (errcode == 0) {
    //           this.$message({
    //             message: "删除成功!",
    //             type: "success",
    //           });
    //           this.initPageData();
    //         } else {
    //           this.$message.error(errmsg);
    //         }
    //       } catch (e) {
    //         console.error(e);
    //       }
    //     })
    //     .catch(() => {});
    // },

    // 打开维度排序对话框
    onShowDimenSort() {
      this.$refs.dimensionSortDialog.onShowDialog(this.dimensionList);
    },
  },
};
</script>

<style lang="less" scoped>
.dimension {
  .df-row {
    justify-content: space-between;
    box-sizing: border-box;

    .df-col {
      ::v-deep .dfWebIcon {
        margin-right: 4px;
        font-size: 13px;
      }
    }
  }
}

.dimension-box {
  .flex-col;
  align-items: unset;

  .dimension-item {
    margin: 5px 0;

    .dimension-name {
      font-size: 15px;
      font-weight: bold;

      i {
        margin-left: 5px;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: #409eff;
        }
      }

      .el-button {
        margin-left: 10px;
      }
    }

    .kind-table {
      margin: 10px 0;

      .kind-logo {
        .flex-row;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        font-size: 14px;
        color: white;
        border-radius: 50%;
      }

      .el-tag {
        margin-right: 10px;
      }
    }
  }
}
</style>
